<template>
    <div class="container">
        <h2>Confirm Device</h2>

        <div class="card-grid vertical">
            <div class="card-link-container" v-for="device in devices" :key="device.id">
                <device-revisit-details-card
                        :device="device"
                        @deviceSelected="deviceSelected(device.id)">
                </device-revisit-details-card>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Device",

        data() {
            return {
                loading: false
            }
        },

        computed: {
            ...mapGetters([
                "devices"
            ]),
        },

        methods: {
            deviceSelected(deviceId) {
                this.$http.post(`/api/v4/warranty/booking/${localStorage.revisitBookingId}/device/${deviceId}`)
                    .then(() => {
                        this.$router.push({name: 'revisit.device-issues'});
                    });
            }
        }
    }
</script>

<style scoped>

</style>
